.main-footers{
    color: white;
    background-color: #E60000;    
    bottom: 0;
    display: flex;
    float: left;   
    left:0;
    right:0;
    width: 100%;
}

.footer-font{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 30px;
}

.page-containerRight{
    display: flex;
    float: right;  
  }

  .button {
    background-color: #E60000; /* Green */
    border: none;
    color: #E60000;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

  .wrapperHeader{
    display: flex;
    justify-content: space-between;
    color: white;
    background-color: #E60000;
    width: 100%;
    position: absolute;
    top: 0;
    height: 150px;
  }  

  .img-circular{
    width: 90px;
    height: 90px;
    background-size: cover;
    display: block;
    border-radius: 90px;
    -webkit-border-radius: 90px;
    -moz-border-radius: 90px;
   }

#container{width:100%;color: white;
  background-color: #E60000;height: 150px;}
#left{float:left;width:100px;}
#right{float:right;width:100px;text-align: right;}
#center{margin:0 auto;width:100px;}



#headerdivcontainer {
  display: flex;                  /* establish flex container */
  flex-direction: row;            /* default value; can be omitted */
  flex-wrap: nowrap;              /* default value; can be omitted */
  justify-content: space-between; /* switched from default (flex-start, see below) */
  color: white;
  background-color: #E60000;
}
#headerdivcontainer > div {
  width: 33%;
}


#cameraImage {
  position: relative;
  left: 60px;
  top: -30px;
}