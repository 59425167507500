

@media only screen and (max-width: 600px) {
    .headerQRImg{
    Max-height:140px !important;
    }
}
@media only screen and (max-width: 780px) {
    .headerQRImg{
    Max-height:270px !important;
    }
}
@media only screen and (min-width: 781px) {
    .headerQRImg{
    Max-height:270px !important;
    }
}

.QRheader{
    color: white;
    position: absolute;
    top: 0;  
    left:0;
    right:0;
}

.headerQRImg{
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 0% 0%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

#QRwrapper {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px; 
    background-color: #E60000;
  }
  
#QRfirst{
    padding: 10px;
}  
#QRsecond {
      padding-top: 20px;
      padding-left: 10px;
      width: 40%
}
#QRthird{
    padding: 10px;
    text-align: right;
    margin-top: 20px;
    width: 50%;
} 

.header-img-circular {
        width: 60px;
        height: 60px;
        background-size: cover;
        display: block;
        border-radius: 60px;
        -webkit-border-radius: 60px;
        -moz-border-radius: 60px;
        margin-top: 8px;
}
