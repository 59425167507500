.main-footer{
    color: white;
    background-color: #E60000;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.kk {
  position: absolute;
  bottom: 0;
  display: flex;
  float: left;  
  left:0;
  right:0;
}

.footer-font{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 30px;
}

.page-containerRight{
    display: flex;
    float: right;  
  }

  .button {
    background-color: #E60000; /* Green */
    border: none;
    color: #E60000;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

  .wrapperdash{    
    justify-content: space-between;
    color: white;
    background-color: #E60000;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: right;
    padding: 10px;
    }
    
    .center {
      margin: auto;
      width: 70px;      
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .right {        
      width: 70px;      
      padding-top: 10px;
      padding-bottom: 10px;      
      padding-right: 20px;      
    }
  