.button {
    background-color: #E60000;
    border: none;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
  }
  
  .button1 {border-radius: 2px;}
  .button2 {border-radius: 4px;}
  .button3 {border-radius: 8px;}
  .button4 {border-radius: 12px;}
  .button5 {border-radius: 50%;}